import { FORGET_PASSWORD } from "../actions/actionTypes";
const initialState = {
  url: "",
};

export default function (state = initialState, action) {
  //  (action.payload);
  switch (action.type) {
    case FORGET_PASSWORD:
      return {
        ...state,
        url: action.payload,
      };

    default:
      return state;
  }
}
