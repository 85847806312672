export const LOGIN = "LOGIN";
export const REGISTER = "REGISTER"
export const LOGOUT = "LOGOUT";
export const ACCESS_TOKEN = 'ACCESS_TOKEN'
export const SHOW_NAV = 'SHOW_NAV'
export const FORGET_PASSWORD = 'FORGET_PASSWORD'
export const GET_ROUND_ID = 'GET_ROUND_ID'
export const SHOW_CALENDER = 'SHOW_CALENDER'
export const IS_QUIZ = "IS_QUIZ"
export const IS_SUBJECT = "IS_SUBJECT"
