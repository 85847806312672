import { GET_ROUND_ID } from "../actions/actionTypes";
const initialState = {
  id: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ROUND_ID:
      return {
        ...state,
        id: action.payload,
      };

    default:
      return state;
  }
}
