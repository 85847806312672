import { combineReducers } from "redux";
import authReducer from "./authReducer";
import tokenReducer from "./tokenReducer";
import showNavReducer from "./showNavReducer";
import passwordReducer from "./passwordReducer";
import RoundIDReducer from "./RoundIDReducer";
import showCalenderReducer from "./showCalenderReducer";
import isQuizReducer from "./isQuizReducer";
import isSubjectReducer from "./isSubjectReducer";

const allReducers = combineReducers({
  auth: authReducer,
  accessToken: tokenReducer,
  showNav: showNavReducer,
  url: passwordReducer,
  id: RoundIDReducer,
  showCalender: showCalenderReducer,
  isQuiz: isQuizReducer,
  isSubject: isSubjectReducer,
});

export default allReducers;
