import { LOGIN, LOGOUT,REGISTER } from "../actions/actionTypes";
const initialState = {
  isAuth: false,
  user: {},
  accessToken: "",
};

export default function (state = initialState, action) {
  //  (action.payload);
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuth: true,
        user: action.payload,
      };
      case REGISTER:
        return {
          ...state,
          // isAuth: false,
        };
    case LOGOUT:
      return { ...state, isAuth: false, user: {} };

    default:
      return state;
  }
}
