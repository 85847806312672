import { SHOW_CALENDER } from "../actions/actionTypes";
const initialState = {
  showCalender: false,
};

export default function (state = initialState, action) {
  //  (action.payload);
  switch (action.type) {
    case SHOW_CALENDER:
      return {
        ...state,
        showCalender: action.payload,
      };

    default:
      return state;
  }
}
